
import React from 'react'
import SortableTable from './components/SortableTable'
import { View } from '@instructure/ui-view'
import { Text } from '@instructure/ui-text'
import Export from './components/Export'
import ExportByWeek from './components/ExportByWeek'
import { Flex } from '@instructure/ui-flex'
import './App.css';
import Search from './components/Search'
import { SimpleSelect } from '@instructure/ui-simple-select'
import { TextInput } from '@instructure/ui-text-input'
import { Tabs } from '@instructure/ui-tabs'
import ShowDTI from "./components/dti/ShowDTI"

Date.prototype.getWeek = function () {
  var target  = new Date(this.valueOf());
  var dayNr   = (this.getDay() + 6) % 7;
  target.setDate(target.getDate() - dayNr + 3);
  var firstThursday = target.valueOf();
  target.setMonth(0, 1);
  if (target.getDay() != 4) {
      target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - target) / 604800000);
}

function getWeekRange(weekNumber, year=null) {
  if (year == null) {
    const d = new Date();
    year = d.getFullYear()
  }
  // Get the first Monday of the given week
  const firstMonday = new Date(year, 0, 4); // January 4th is always in the first ISO week
  firstMonday.setDate(firstMonday.getDate() - ((firstMonday.getDay() + 6) % 7) + (weekNumber - 1) * 7);
  
  const weekStart = new Date(firstMonday);
  const weekEnd = new Date(firstMonday);
  weekEnd.setDate(weekEnd.getDate() + 6); // Sunday

  // Format to Vietnamese format (DD/MM/YYYY)
  const formatVN = (date) => date.toLocaleDateString('vi-VN');

  return {
      start_date: formatVN(weekStart),
      end_date: formatVN(weekEnd)
  };
}

function timeToHuman (updated_at){
  return (function(time) {
    var a = (new Date(time)).toString().split(/\s/);
    return a[2] + "/" + {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
    }[a[1]] + "/" + a[3] + " " + a[4]
  }(new Date(updated_at)));
}

function App() {
  const [date, setDate] = React.useState(new Date());
  const [week, setWeek] = React.useState((new Date()).getWeek() - 1);
  const [data, setData] = React.useState(null);
  const [dataByWeek, setDataByWeek] = React.useState(null);
  const [subjects, setSubjects] = React.useState(null);
  const [value_subject, setValueSubjects] = React.useState('');

  const [lms_domain, setLmsDomain] = React.useState(null);
  
  const [search_data, setSearchData] = React.useState(null);
  const [searchDataByWeek, setSearchDataByWeek] = React.useState(null);
  const [search_string, setSearchString] = React.useState("");
  const [search_by, setSearchBy] = React.useState('teacher');
  const [placeholder, setPlaceHolder] = React.useState("Tìm kiếm theo tên giáo viên");
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleTabChange = (event, { index, id }) => {
    setSelectedIndex(index)
  }
  
  React.useEffect(() => {
    fetch("/api/v1/session")
      .then((res) => res.json())
      .then((data) => {
        setLmsDomain(data.custom_domain)
        fetch(`/api/v1/teachers?custom_root_account_uuid=${encodeURIComponent(data.custom_root_account_uuid)}&custom_canvas_account_id=${encodeURIComponent(data.custom_canvas_account_id)}`)
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setSubjects(data.subjects)
        })

        fetch(`/api/v1/teachers_byweek?year=${(new Date()).getFullYear()}&week_number=${week}&custom_root_account_uuid=${encodeURIComponent(data.custom_root_account_uuid)}&custom_canvas_account_id=${encodeURIComponent(data.custom_canvas_account_id)}`)
        .then((res) => res.json())
        .then((data) => {
          setDataByWeek(data.data);
          setSubjects(data.subjects)
        })
      });
  }, []);

  React.useEffect(() => {
    fetch("/api/v1/session")
      .then((res) => res.json())
      .then((data) => {
        setLmsDomain(data.custom_domain)
        fetch(`/api/v1/teachers_byweek?year=${(new Date()).getFullYear()}&week_number=${week}&custom_root_account_uuid=${encodeURIComponent(data.custom_root_account_uuid)}&custom_canvas_account_id=${encodeURIComponent(data.custom_canvas_account_id)}`)
        .then((res) => res.json())
        .then((data) => {
          setDataByWeek(data.data);
          setSubjects(data.subjects)
        })
      });
  }, [week]);
  
  function search(event, value) {
    value = value.toLowerCase()
    setSearchString(value);
    if (search_by == 'teacher') {
      var search_data = data.filter(word => word["GiaoVienID"].toLowerCase().includes(value) || word["name"].toLowerCase().includes(value))
      setSearchData(search_data);
    } else {
      var search_data = data.filter(word => word['courses'].find(course => course['TenMon'].toLowerCase().includes(value) > 0))
      setSearchData(search_data);
    }
  }

  function searchByWeek(event, value) {
    value = value.toLowerCase()
    setSearchString(value);
    if (search_by == 'teacher') {
      var search_data = dataByWeek.filter(word => word["GiaoVienID"].toLowerCase().includes(value) || word["name"].toLowerCase().includes(value))
      setSearchDataByWeek(search_data);
    } else {
      var search_data = dataByWeek.filter(word => word['courses'].find(course => course['TenMon'].toLowerCase().includes(value) > 0))
      setSearchDataByWeek(search_data);
    }
  }

  function search_filter(by, value) {
    value = value.toLowerCase()
    setSearchString(value);
    if (by == 'teacher') {
      var search_data = data.filter(word => word["GiaoVienID"].toLowerCase().includes(value) || word["name"].toLowerCase().includes(value))
      setSearchData(search_data);
      var search_data_w = dataByWeek.filter(word => word["GiaoVienID"].toLowerCase().includes(value) || word["name"].toLowerCase().includes(value))
      setSearchDataByWeek(search_data_w);
    } else {
      var search_data = data.filter(word => word['courses'].find(course => course['TenMon'].toLowerCase().includes(value) > 0))
      setSearchData(search_data);
      var search_data_w = dataByWeek.filter(word => word['courses'].find(course => course['TenMon'].toLowerCase().includes(value) > 0))
      setSearchDataByWeek(search_data_w);
    }
  }

  function searchBy(event, data) {
    if (data.value == 'teacher') {
      setPlaceHolder("Tìm kiếm theo tên giáo viên")
      search_filter(data.value, '');
    } else {
      if (value_subject == '')
        search_filter(data.value, subjects[0]);
      else
        search_filter(data.value, value_subject);
    }
    setSearchBy(data.value);
  }

  function handleSelect (e, { id, value }) {
    setValueSubjects(value)
    console.log(value)
    var search_data = data.filter(word => word['courses'].find(course => course['TenMon'] == value))
    setSearchData(search_data);
  }

  function renderDropDown() {
    if (search_by == 'teacher') {
      return (
        <TextInput placeholder={placeholder}
                onChange={search}/>
      )
    } else {
      return (
        <SimpleSelect
          value={value_subject}
          onChange={handleSelect}
        >
          {subjects.map((opt, index) => (
            <SimpleSelect.Option
              key={index}
              id={`opt-${index}`}
              value={opt}
            >
              { opt }
            </SimpleSelect.Option>
          ))}

        </SimpleSelect>
      )
    }
    
  }

  function changeWeek (e, { id, value }) {
    setWeek(value);
  }

  return (
    <View
        as="div"
        margin="small"
        padding="small"
        background="primary"
      >
        <Tabs
        margin="small auto"
        padding="small"
        onRequestTabChange={handleTabChange}
      >
        <Tabs.Panel
          id="tkhd"
          renderTitle="Thống kê hoạt động"
          textAlign="center"
          padding="large"
          isSelected={selectedIndex === 0}
        >
          <Export/>
          <Text>Cập nhật lần cuối: {!data ? "Loading..." : data.length > 0 ? timeToHuman(data[0].updated_at) : "Dữ liệu trống"}</Text>
          <Search search={search} searchBy={searchBy} placeholder={placeholder} renderDropDown={renderDropDown}/>
          {!data ? <></> : data.length <= 0 ? 
          <Flex justifyItems="center" margin="large">
            <Flex.Item>
            <Text>Không có dữ liệu</Text>
            </Flex.Item>
          </Flex> :
          <SortableTable
            headers={[
              {
                id: 'GiaoVienID',
                text: 'GiaoVienID',
              },
              {
                id: 'name',
                text: 'Họ và tên',
                width: '40%',
              },
              {
                id: 'num_of_modules',
                text: 'Tổng module đã công bố',
              },
              {
                id: 'num_of_items',
                text: 'Tổng nội dung đã công bố',
              }
            ]}
            rows={search_data ? search_data : data}
            perPage={10}
            lms_domain={lms_domain}
          />}
        </Tabs.Panel>
        <Tabs.Panel id="week-tab" renderTitle="Thống kê theo tuần" isSelected={selectedIndex === 1}>
          <ExportByWeek week={week}/>
          <Text>Cập nhật lần cuối: {!dataByWeek ? "Loading..." : dataByWeek.length > 0 ? timeToHuman(dataByWeek[0].updated_at) : "Dữ liệu trống"}</Text>
          <View
              as="div"
              padding="small 0"
              background="primary"
            >
              <SimpleSelect onChange={changeWeek} defaultValue={week}>
                {Array.from(Array(date.getWeek()).keys()).map( e => {
                  if (e > 0) return (<SimpleSelect.Option id={e} value={e}>{`Tuần ${e} (Từ ${getWeekRange(e).start_date} đến ${getWeekRange(e).end_date})`}</SimpleSelect.Option>)
                })}
              </SimpleSelect>
          </View>
          <Search search={searchByWeek} searchBy={searchBy} placeholder={placeholder} renderDropDown={renderDropDown}/>
          {!dataByWeek ? <></> : dataByWeek.length <= 0 ? 
          <Flex justifyItems="center" margin="large">
            <Flex.Item>
            <Text>Không có dữ liệu theo tuần</Text>
            </Flex.Item>
          </Flex> :
          <SortableTable
            headers={[
              {
                id: 'GiaoVienID',
                text: 'GiaoVienID',
              },
              {
                id: 'name',
                text: 'Họ và tên',
                width: '40%',
              },
              {
                id: 'num_of_modules',
                text: 'Tổng module đã công bố',
              },
              {
                id: 'num_of_items',
                text: 'Tổng nội dung đã công bố',
              }
            ]}
            rows={searchDataByWeek ? searchDataByWeek : dataByWeek}
            perPage={10}
            lms_domain={lms_domain}
          />}
        </Tabs.Panel>
        <Tabs.Panel id="dti-tab" renderTitle="Báo cáo DTI" isSelected={selectedIndex === 2}>
          <ShowDTI />
        </Tabs.Panel>
      </Tabs>
    </View>
  );
}

export default App;
